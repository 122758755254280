import React, {lazy} from 'react';
import {Routes, Route} from "react-router-dom";
import AppRoutes from "./constants/appRoutes";


//layout
import AuthLayout from "./layouts/authLayout";
import AdminLayout from "./layouts/admin-layout/AdminLayout";
import EmptyLayout from "./layouts/empty-layout/EmptyLayout";
//providers
import PriceProvider from "./contexts/priceProvider";
import CoinProvider from "./contexts/coinProvider";
import PermissionProvider from "./contexts/permissionProvider";

// code splitting
import SuspenseWrapper from "./suspenseWrapper";


const TicketMessage = lazy(() => import( "./pages/user-panel/ticketMessage"));

//auth
const LoginPage = lazy(() => import( "./pages/auth/loginPage"));

//404 page
const NotFound = lazy(() => import( "./pages/notFound"));


// admin panel
const AdminDashboard = lazy(() => import("./pages/admin-panel/adminDashboard"));
const AdminInvest = lazy(() => import("./pages/admin-panel/adminInvset"));
const AdminTradersPage = lazy(() => import("./pages/admin-panel/copyTrading/adminTradersPage"));
const AdminTraderTransaction = lazy(() => import("./pages/admin-panel/copyTrading/adminTraderTransaction"));
const KucoinPage = lazy(() => import("./pages/admin-panel/kucoin"));
const UserProfile = lazy(() => import("./pages/admin-panel/userProfile"));
const WithdrawalTrx = lazy(() => import("./pages/admin-panel/withdrawalTrx"));
const AdminDepositCrypto = lazy(() => import("./pages/admin-panel/AdminDepositCrypto"));
const AdminDepositIrt = lazy(() => import("./pages/admin-panel/AdminDepositIrt"));
const AdminCoins = lazy(() => import("./pages/admin-panel/adminCoins"));
const AdminFuturesCoins = lazy(() => import("./pages/admin-panel/AdminFuturesCoin"));
const AdminUsers = lazy(() => import("./pages/admin-panel/adminUsers"));
const AdminTransactions = lazy(() => import("./pages/admin-panel/adminTransactions"));
const AdminCards = lazy(() => import("./pages/admin-panel/adminCards"));
const AdminTickets = lazy(() => import("./pages/admin-panel/adminTicket"));
const Admins = lazy(() => import("./pages/admin-panel/admins"));
const AdminContent = lazy(() => import("./pages/admin-panel/adminContent"));
const AdminTraderMembers = lazy(() => import( "./pages/admin-panel/copyTrading/adminTraderMembers"));
const AdminSecurity = lazy(() => import( "./pages/admin-panel/adminSecurity"));
const AdminAccounting = lazy(() => import( "./pages/admin-panel/adminAccounting"));
const RewardPage = lazy(() => import( "./pages/admin-panel/rewardPage"));
const AdminFuturesPage = lazy(() => import( "./pages/admin-panel/AdminFuturesPage"));


function RouteManager() {
    return (
        <Routes>
            <Route path={'/'} element={<PriceProvider/>}>
                <Route path={''} element={<CoinProvider/>}>
                    <Route element={<EmptyLayout/>}>
                        <Route path={AppRoutes.LOGIN} element={<SuspenseWrapper><LoginPage/></SuspenseWrapper>}/>
                        {/*<Route path={AppRoutes.REGISTER}*/}
                        {/*       element={<SuspenseWrapper><RegisterPage/></SuspenseWrapper>}/>*/}
                    </Route>
                    {/*admin pannel*/}
                    <Route element={<AuthLayout/>} path={AppRoutes.ADMIN}>
                        <Route path={''} element={<PermissionProvider/>}>
                            <Route element={<AdminLayout/>} path={''}>
                                {/*<Route*/}
                                {/*    key={'admin-panel-dashboard'}*/}
                                {/*    path={AppRoutes.ADMIN_SERVER}*/}
                                {/*    element={<SuspenseWrapper><AdminServer/></SuspenseWrapper>}*/}
                                {/*/>*/}
                                <Route
                                    key={'admin-panel-dashboard'}
                                    path={AppRoutes.ADMIN_DASHBOARD}
                                    element={<SuspenseWrapper><AdminDashboard/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-users'}
                                    path={AppRoutes.ADMIN_USERS}
                                    element={<SuspenseWrapper><AdminUsers/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-user'}
                                    path={AppRoutes.ADMIN_USER}
                                    element={<SuspenseWrapper><UserProfile/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-trx'}
                                    path={AppRoutes.ADMIN_TRANSACTION}
                                    element={<SuspenseWrapper><AdminTransactions/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-invset'}
                                    path={AppRoutes.ADMIN_INVSET}
                                    element={<SuspenseWrapper><AdminInvest/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-withdrawal-trx'}
                                    path={AppRoutes.ADMIN_WITHDRAWAL_TRANSACTION}
                                    element={<SuspenseWrapper><WithdrawalTrx/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-deposit-trx'}
                                    path={AppRoutes.ADMIN_DEPOSIT_TRANSACTION}
                                    element={<SuspenseWrapper><AdminDepositCrypto/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-deposit-irt-trx'}
                                    path={AppRoutes.ADMIN_DEPOSIT_IRT_TRANSACTION}
                                    element={<SuspenseWrapper><AdminDepositIrt/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-cards'}
                                    path={AppRoutes.ADMIN_CARDS}
                                    element={<SuspenseWrapper><AdminCards/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-ticket'}
                                    path={AppRoutes.ADMIN_TICKET}
                                    element={<SuspenseWrapper><AdminTickets/></SuspenseWrapper>}
                                />

                                <Route
                                    key={'admin-panel-support-ticket-detail'}
                                    path={AppRoutes.ADMIN_TICKET_DETAIL}
                                    element={<SuspenseWrapper><TicketMessage/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-admins'}
                                    path={AppRoutes.ADMINS}
                                    element={<SuspenseWrapper><Admins/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-content'}
                                    path={AppRoutes.ADMIN_CONTENT}
                                    element={<SuspenseWrapper><AdminContent/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-coins'}
                                    path={AppRoutes.ADMIN_COINS}
                                    element={<SuspenseWrapper><AdminCoins/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-futures-coins'}
                                    path={AppRoutes.ADMIN_FUTURES_COINS}
                                    element={<SuspenseWrapper><AdminFuturesCoins/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-kucoin'}
                                    path={AppRoutes.ADMIN_KUCOIN}
                                    element={<SuspenseWrapper><KucoinPage/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-security'}
                                    path={AppRoutes.ADMIN_SECURITY}
                                    element={<SuspenseWrapper><AdminSecurity/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-accountancy'}
                                    path={AppRoutes.ADMIN_ACCOUNTANCY}
                                    element={<SuspenseWrapper><AdminAccounting/></SuspenseWrapper>}
                                />

                                <Route
                                    key={'admin-panel-traders'}
                                    path={AppRoutes.ADMIN_TRADER}
                                    element={<SuspenseWrapper><AdminTradersPage/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-trader-transactions'}
                                    path={AppRoutes.ADMIN_TRADER_TRANSACTION}
                                    element={<SuspenseWrapper><AdminTraderTransaction/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-trader-members'}
                                    path={AppRoutes.ADMIN_TRADER_MEMBERS}
                                    element={<SuspenseWrapper><AdminTraderMembers/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-reward'}
                                    path={AppRoutes.ADMIN_REWARD}
                                    element={<SuspenseWrapper><RewardPage/></SuspenseWrapper>}
                                />
                                <Route
                                    key={'admin-panel-reward'}
                                    path={AppRoutes.ADMIN_FUTURES}
                                    element={<SuspenseWrapper><AdminFuturesPage/></SuspenseWrapper>}
                                />

                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route path={'*'} element={<SuspenseWrapper><NotFound/></SuspenseWrapper>}/>
        </Routes>
    )
}

export default RouteManager;
