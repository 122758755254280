enum AppRoutes {
    // home

    LANDING_PAGE = '/',
    LIVE_PRICE = '/live-price',
    FAQ = '/faq',
    TERM = '/term',
    ABOUT_US = '/about-us',
    CONTACT_US = '/contact-us',
    EARN_MONEY_DESC = '/earn-money',
    WHY_VERSLAND = '/why-versland',
    COIN_CONTENT = '/buy-and-sell/:symbol',
    SPOT_TRADE = "/spot-trade",
    COPY_TRADING_DESC = "/copy-trading",
    STAKING_MAIN = "/staking",
    TRADER_PROFILE_DESC = "/trader/desc",
    PAYMENT_RESULT = "/payment-result",


    // auth
    LOGIN = "/",
    REGISTER = "/register",
    VERIFICATION = "/verification",


    //user panel
    USER = "/user",
    IDENTITY = '/user/identity',
    FACE_IMG = '/user/img',
    TRANSACTION = "/user/trx",
    WALLET = "/user/wallet",
    CARDS = "/user/cards",
    SUPPORT = "/user/support",
    TICKET = "/user/support/ticket",
    TICKET_DETAIL = "/user/support/ticket/detail",
    PORTFOLIO = "/user/portfolio",
    TRADE = "/user/trade",
    USER_SPOT_TRADE = "/user/spot-trade",
    EARN_MONEY = "/user/earn-money",
    INVEST = "/user/invest",
    COPY_TRADING = "/user/copy-trading",
    USER_TRADER_PROFILE = "/user/trader-profile",
    SECURITY = "/user/security",
    STAKING = "/user/staking",

    //admin panel
    ADMIN = '/admin',
    ADMIN_USERS = '/admin/users',
    ADMIN_SERVER = '/admin/server-status',
    ADMIN_DASHBOARD = '/admin/dashboard',
    ADMIN_USER = '/admin/user',
    ADMINS = '/admin/co',
    ADMIN_TRANSACTION = '/admin/trx',
    ADMIN_INVSET = '/admin/invset',
    ADMIN_WITHDRAWAL_TRANSACTION = '/admin/trx/withdrawal',
    ADMIN_DEPOSIT_TRANSACTION = '/admin/trx/deposit',
    ADMIN_DEPOSIT_IRT_TRANSACTION = '/admin/trx/deposit-irt',
    ADMIN_TICKET = '/admin/support',
    ADMIN_TICKET_DETAIL = '/admin/support/ticket/detail',
    ADMIN_CARDS = '/admin/cards',
    ADMIN_CONTENT = '/admin/content',
    ADMIN_COINS = '/admin/coins',
    ADMIN_FUTURES_COINS = '/admin/futures-coins',
    ADMIN_KUCOIN = '/admin/kucoin',
    ADMIN_SECURITY = '/admin/security',
    ADMIN_ACCOUNTANCY = '/admin/accountancy',
    ADMIN_EMAIL = '/admin/email',
    ADMIN_TRADER = '/admin/traders',
    ADMIN_TRADER_TRANSACTION = '/admin/trader/transactions',
    ADMIN_TRADER_MEMBERS = '/admin/trader/members',
    ADMIN_REWARD = '/admin/reward' ,
    ADMIN_EVENT = '/admin/events',
    ADMIN_EVENT_MEMBER = '/admin/event/member',
    ADMIN_EVENT_TRANSACTION = '/admin/event/transaction',
    ADMIN_FUTURES = '/admin/futures',


    // trader panel
    TRADER = '/trader',
    TRADER_DASHBOARD = '/trader/dashboard',
    TRADER_COPY_TRADING = '/trader/copy-trading',
    TRADER_PROFILE = '/trader/profile',
    TRADER_EVENT = '/trader/event',
    TRADER_EVENT_MEMBER = '/trader/event/member',
    TRADER_EVENT_TRANSACTION = '/trader/event/transaction',


}

export default AppRoutes;
